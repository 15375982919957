/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React from 'react';

dayjs.locale('ko');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

export const nl2br = (text: string) => {
  if (!text) {
    return <></>;
  }
  return (
    <>
      {text
        .replace(/</gi, '&lt;')
        .replace(/>/gi, '&gt;')
        .replace(/\r\n|\n\r|\n|\r/gi, '\n')
        .split('\n')
        .map((l, i) => (
          // eslint-disable-next-line react/no-danger
          <React.Fragment key={`nl2brsplit-${l.slice(0, 2)}-${i}`}>
            {l.length > 0 ? (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`sp-${l.slice(0, 2)}-${i}`} dangerouslySetInnerHTML={{ __html: l }} />
            ) : (
              <br />
            )}
          </React.Fragment>
        ))}
    </>
  );
};

const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
export const dayjsKr = (d?: string | Date) => dayjs(d).tz('Asia/Seoul');

export const formatDate = (d: string) => {
  const date = dayjsKr(d).format('M/D');
  const day = dayOfWeek[dayjsKr(d).day()];
  const ampm = dayjsKr(d).hour() >= 12 ? 'PM' : 'AM';
  const time = dayjsKr(d).format('HH:mm');
  return `${date}(${day}) ${time}${ampm}`;
};

export const formatDateAndDay = (d: string) => {
  const date = dayjsKr(d).format('YYYY.M.D');
  const day = dayOfWeek[dayjsKr(d).day()];
  return `${date}(${day})`;
};

export const formatDateAndDayTime = (d: string) => {
  const date = dayjsKr(d).format('YYYY.M.D');
  const day = dayOfWeek[dayjsKr(d).day()];
  const time = dayjsKr(d).format('HH:mm');
  return `${date}(${day}) ${time}`;
};

export const formatDotsDate = (d: string) => dayjs(d).format('YYYY. M. D');

export const formatKSTDate = (d: string) => {
  const dt = dayjsKr(d);
  const date = dt.format('M월 D일');
  const day = dayOfWeek[dt.day()];
  const post = dt.hour() >= 12 ? '오후' : '오전';
  const hour = dt.format('h시');
  const min = dt.format('m');

  const kst = `${date}(${day}) ${post} ${hour}`;
  if (min) {
    kst.concat(` ${min}분`);
  }
  return kst;
};

export const formatBriefKSTDate = (d: string) => {
  const dt = dayjsKr(d);
  return dt.format('M월 D일');
};

export const formatBriefYYYYMD = (d: string) => {
  const dt = dayjsKr(d);
  return dt.format('YYYY년 M월 D일');
};

export const format24YYYYMDdayHHmm = (d: string) => {
  const dt = dayjsKr(d);
  const date = dt.format('YYYY년 M월 D일');
  const day = dayOfWeek[dt.day()];
  const time = dt.format('HH:mm');

  return `${date}(${day}) ${time}`;
};

export const format24hrDate = (d: string) => {
  const dt = dayjsKr(d);
  const date = dt.format('M월 D일');
  const day = dayOfWeek[dt.day()];
  const time = dt.format('HH:mm');

  return `${date}(${day}) ${time}`;
};

export const formatKrDateAndDay = (d: string) => {
  const dt = dayjsKr(d);
  const date = dt.format('M월 D일');
  const day = dayOfWeek[dt.day()];

  return `${date}(${day})`;
};

export const format24hrDateWithTimeRange = (d: string, duration: number) => {
  const dt = dayjsKr(d);
  const date = dt.format('M월 D일');
  const day = dayOfWeek[dt.day()];
  let range = dt.format('HH:mm');
  if (duration) {
    const end = dt.add(duration, 'minute');
    range += ` ~ ${end.format('HH:mm')}`;
  }

  return `${date}(${day}) ${range}`;
};

export const getDayOfWeek = (d: string) => {
  const dt = dayjsKr(d);
  const day = dayOfWeek[dt.day()];
  return `${day}요일`;
};

export const getAMPMinKr = (d: string) => {
  const dt = dayjsKr(d);
  const ampm = dt.hour() > 12 ? '오후' : '오전';
  return ampm;
};

export const getYear = (d: string) => dayjsKr(d).year();

export const getMeetupTimeRange = (startAt: string, durationMinute: number) => {
  const start = dayjsKr(startAt);
  let range = start.format('HH:mm');
  if (durationMinute) {
    const end = start.add(durationMinute, 'minute');
    range += ` ~ ${end.format('HH:mm')}`;
  }
  return range;
};

export const isPast = (d: string) => {
  const today = dayjsKr();
  return today.diff(d) > 0;
};

export const getStartOfDate = (d: string) => dayjsKr(d).startOf('day').toDate();

export const getEndOfDate = (d: string) => dayjsKr(d).endOf('day').toDate();

const NON_MEMBER_BUFFER_DAY = 1;
export const getNonMemberOpeningDate = openingDate => {
  const date = dayjsKr(openingDate);
  return date.add(NON_MEMBER_BUFFER_DAY, 'day');
};

export const getMonthDate = (d: string) => {
  const date = dayjsKr(d);
  return `${date.month() + 1}/${date.date()}`;
};

export const getMonthDateWithTitle = (d: string) => {
  const date = dayjsKr(d);
  return `${date.month() + 1}월 ${date.date()}일`;
};

export const getRemainingDays = (d: string) => calculateDayDifference(new Date(d), new Date());

export const getPastDays = (d: string) => calculateDayDifference(new Date(), new Date(d));

export const calculateDayDifference = (date1: Date, date2: Date) =>
  dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day');

export const getDifferenceInDaysKr = (d: string) => {
  const nowDate = new Date();
  const fromDate = new Date(d);
  const differenceInTime = nowDate.getTime() - fromDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  if (differenceInDays < 1) {
    return '방금 전';
  }
  if (differenceInDays < 7 && differenceInDays >= 1) {
    return `${Math.floor(differenceInDays)}일 전`;
  }
  if (differenceInDays >= 7 && differenceInDays < 21) {
    return `${Math.floor(differenceInDays / 7)}주일 전`;
  }
  return '';
};

export const getDday = (d: string) => {
  const today = dayjsKr().startOf('day');
  const expiredAt = dayjsKr(d).startOf('day');
  const result = expiredAt.diff(today, 'day', true);
  return Math.floor(result);
};

export const getDiffDayAndTimeText = (d: string) => {
  const today = dayjsKr().startOf('day');
  const targetDate = dayjsKr(d);
  const targetDateStartOfDay = targetDate.startOf('day');
  
  const diffDays = today.diff(targetDateStartOfDay, 'day');
  const diffMinutes = today.diff(targetDate, 'minute');

  if (diffDays <= 1) {
    if (diffMinutes < 1) return `방금 전`;
    if (diffMinutes < 60) return `${diffMinutes}분 전`;
    return `${Math.floor(diffMinutes / 60)}시간 전`;
  }
  if (diffDays < 30) return `${diffDays}일 전`;
  if (diffDays < 365) return `${Math.floor(diffDays / 30)}개월 전`;
  return `${Math.floor(diffDays / 365)}년 전`;
};

export const buildHashtag = (keyword: string) => {
  if (keyword.startsWith('#')) {
    return keyword;
  }
  return `#${keyword}`;
};

export const isEndWithConsonant = (korStr: string) => {
  const finalChrCode = korStr?.charCodeAt(korStr?.length - 1);
  const finalConsonantCode = (finalChrCode - 44032) % 28;
  return finalConsonantCode !== 0;
};

export const removeEmoji = (str: string) => {
  const regExp = RegExp(
    // eslint-disable-next-line no-control-regex, max-len
    /[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]/,
  );

  if (str.match(regExp)) {
    // eslint-disable-next-line no-param-reassign
    str = str.replace(regExp, '');
  }

  return str;
};

export const getSplitDecodeValue = (value: string) =>
  value?.split(',')?.map(d => decodeURIComponent(d)) ?? [];
