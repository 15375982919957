export interface ApiResponse<T> {
  success: boolean;
  message: string | null;
  data: T;
}

export interface PaginationRequest {
  limit?: number;
  offset?: number;
}

export interface PaginationResponse {
  nextPage: string | null;
  prevPage: string | null;
}

export enum MeetupDescriptionTextFormat {
  PLAIN_TEXT = "plain_text",
  MARKDOWN = "markdown",
  NOTION = "notion",
}

export interface Meetup {
  id: string;
  title: string;
  host: User | null; // 자유모임인 경우 null일 수 있음
  thumbnailUrl: string;
  briefLocation: string; // 최상위 공개용 위치 (ex. 강남 인근), 상세 위치는 Session 참고
  openingDate: string | null;
  closingDate: string | null;
  createdAt: string;
  releasedAt: string | null;
  canceledAt: string | null;
  type: MeetupType;
  summary: string | null;
  comment: string | null;
  description: string | null;
  descriptionTextFormat: MeetupDescriptionTextFormat | null;
  minCapacity: number;
  maxCapacity: number;
  attendeeCount: number;
  femaleCapacity: number | null;
  femaleCount: number | null;
  maleCapacity: number | null;
  maleCount: number | null;
  price: number;
  discountPrice: number | null;
  tags: { [key: string]: string[] };
  contents: Content[]; // 책, 영화, 시리즈, 유튜브 모두 있을 수 있음
  sessions: Session[];
  additionalInformation?: {
    memberLed?: boolean;
    group?: {
      id: string;
      meetupIds: string[];
    };
    visit?: {
      price: number;
    };
    reopeningHost?: boolean;
    firstSessionGathering?: boolean;
    interviewRequired?: boolean;
  };
  openChatUrl: string | null;
  hostIntroduction: string | null;
  placeName?: string | null;
  placeAddress?: string | null;
  extraImageUrls?: any;
  preMeetupPrompt?: string | null;

  // FIXME: DEPRECATED, 사용처 삭제
  applyUrl: string | null; // 결제 기능 넣기 전 임시 사용 용도
  openNotificationWishCount: number;
  reopeningWishCount: number;
  waitlistWishCount: number;
  bookmarkWishCount: number;
  visitWaitlistWishCount: number;
}

export interface AttendeeStatistics {
  age: {
    "20대": number;
    "30대": number;
    "40대": number;
    "50대": number;
    기타: number;
  };
  gender: {
    기타: number;
    남성: number;
    여성: number;
  };
  salonPurpose?: {
    "관계/친목": number;
    대화: number;
    "주제 학습/워크숍": number;
  };
}

export interface BarData {
  title: string;
  barWidth: number;
  barColor: string;
}

export interface Introduction {
  introduction: string;
  salonCategory: string[];
  createdAt: string;
}

export interface UserInteraction {
  attending: boolean;
  wishes: Wish[];
}

export interface MeetupResponse {
  meetup: Meetup;
  raffles: Raffle[];
  attendeeStatistics: AttendeeStatistics | null;
  attendeeIntroductions: Introduction[] | null;
  userInteraction: UserInteraction | null;
}

export interface IntroductionResponse {
  introductions: Introduction[];
}

export interface VisitableMeetupsResponse {
  visitableMeetups: {
    meetup: Meetup;
    upcomingSessionId: number;
    availableSeatCount: number;
    wishes: Wish[];
  }[];
  pagination: PaginationResponse;
}

export interface VisitableMeetupResponse {
  meetup: Meetup;
  upcomingSessionId: number;
  availableSeatCount: number;
  visitCapacity: number;
  wishes: Wish[];
}

export interface RecentlyAttendedMeetupsResponse {
  recentlyAttendedMeetups: {
    meetup: Meetup;
    wishes: Wish[];
    lastAttendedDate: string;
  }[];
  pagination: PaginationResponse;
}

export interface MeetupWithWishes {
  meetup: Meetup;
  wishes: Wish[];
}

export interface MeetupsResponse {
  meetups: MeetupWithWishes[];
  pagination: PaginationResponse;
  totalCount: number;
}

export interface MetUser {
  user: User;
  metCount: number;
  lastMetDate: string;
  metMeetupTitles: {
    id: number;
    title: string;
  }[];
}

export interface MetUsersResponse {
  metUsers: MetUser[];
  pagination: PaginationResponse;
}

// 각종 필터 대응을 위한 메타 카테고리 느낌, 요일, 카테고리, 운영용 selection 모두 대응 가능
// ex. category: '정기모임 카테고리', tag: '사랑과 연애'
// ex. category: '요일', tag: '월요일'
export interface TagsResponse {
  [category: string]: string[];

  // FIXME: wildcard key 랑 ts 에러없이 병기할 수 있는 방법 찾기..
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  pagination: PaginationResponse;
}

export interface UserResponse {
  user: User;
}

export interface AttendancesResponse {
  attendances: Attendance[];
  pagination: PaginationResponse;
}

export interface Attendee {
  id: number;
  name: string;
  phoneNumber: string;
  role: string;
  gender: string | null;
  presences: {
    sessionId: number;
    present?: boolean;
  }[];
}

export interface AttendeeListResponse {
  attendeeList: Attendee[];
}

export interface PromptsResponse {
  prompts: PromptIncludingRecentAnswers[];
  pagination: PaginationResponse;
}

export interface PromptIncludingRecentAnswers {
  prompt: Prompt;
  recentAnswers: Answer[];
}

export interface PromptResponse {
  prompt: Prompt;
}

export interface AnswersResponse {
  answers: AnswerIncludingEmojiReactions[];
  pagination: PaginationResponse;
}

export interface AnswerIncludingEmojiReactions {
  answer: Answer;
  recentEmojiReactions: EmojiReaction[];
  myEmojiReactions?: EmojiReaction[];
}

export interface UploadImageUrlResponse {
  uploadUrl: string;
}

export interface AnswerResponse {
  answer: Answer;
}

export interface ReviewResponse {
  review: Review;
}

export interface ReviewIncludingEmojiReactions {
  review: Review;
  recentEmojiReactions: EmojiReaction[];
  myEmojiReactions?: EmojiReaction[];
}

export interface ReviewsResponse {
  reviews: ReviewIncludingEmojiReactions[];
  pagination: PaginationResponse;
  avgRating: number | null;
  totalRatingCount: number | null;
  totalPhotoReviewCount: number | null;
}

export interface WishedMeetupResponse {
  wishedMeetups: WishedMeetup[];
  pagination: PaginationResponse;
}

export interface WishedMeetup {
  wishes: Wish[];
  meetup: Meetup;
}

export interface WishResponse {
  wish: Wish;
  syncedWishes: Wish[];
}

export interface WishCountResponse {
  count: number;
}

export interface Wish {
  id: number;
  meetupId: number | null;
  sessionId: number | null;
  hostId: number | null;
  type: number;
}

export interface NotificationsResponse {
  notifications: Notification[];
}

export interface Notification {
  id: number;
  meetupId: number | null;
  title: string | null;
  body: string;
  channel: number;
  createdAt: string;
  sentAt: string | null;
  reservedDate: string | null;
  sender: MemberType;
  marketing: number | null;
}

export interface FeedbackResponse {
  feedbacks: Feedback[];
}

export interface Feedback {
  id: number;
  source: string;
  subject: string;
  meetupId: number | null;
  sessionOrder: number | null;
  body: string;
  createdAt: string;
}

export enum FeedbackSubject {
  MEETUP = "meetup",
  OPERATION = "operation",
  HOST = "host",
}

export enum FeedbackSource {
  HOST = "host",
  ASSISTANT = "assistant",
  REGISTERED_ATTENDEE = "registered_attendee",
  VISITOR = "visiting_attendee",
  INSIDER = "insider",
}

export interface PaycheckResponse {
  paycheck: Paycheck;
}

export interface PaychecksResponse {
  paychecks: Paycheck[];
}

export interface Paycheck {
  id: number;
  userId: string;
  meetupId: string;
  session: Session | null;
  type: string;
  state: number;
  createdAt: string | null;
  taxType: TaxType;
  paidAmount: number | null;
  paidAt: string | null;
  paymentDocuments: PaymentDocument[];
}

export interface PaymentDocumentResponse {
  paymentDocuments: PaymentDocument[];
}

export interface PaymentDocument {
  id: number;
  imageUrl: string;
  data?: {
    name?: string;
    birthday?: string;
    identificationNumber?: string;
    type?: string;
    accountNumber?: string;
    accountHolder?: string;
    bankName?: string;
    paymentAmount?: number;
  };
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface TaxType {
  id: number;
  name: string;
  taxRate: number;
}

export interface RolesResponse {
  roles: Role[];
}

export interface Role {
  id: number;
  meetupId: number | null;
  user: User;
  role: RoleType;
  status: RoleStatusType;
}

export interface Answer {
  id: number;
  promptId: number;
  user: User;
  answerText: string | null;
  answerImageUrl: string | null;
  answerChoices: string[] | null;
  accessScope: number;
  emojiReactionCount: number;
  createdAt: string;
  updatedAt: string;
}

export interface Prompt {
  id: number;
  user: User;
  meetupId: number;
  sessionId: number | null;
  question: string;
  description: string | null;
  choices: string[] | null;
  type: number;
  answerAccessScope: number | null;
  answerType: number;
  answerCount: number;
  recentAnsweringUsers: User[] | [];
  lastAnswerCreatedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface Review {
  id: number;
  user: User;
  meetup: Meetup;
  rating: number;
  body: string;
  photoUrls?: string[];
  visibilityScope: number;
  emojiReactionCount: number;
  recentEmojiReactingUsers: User[] | [];
  lastEmojiReactionCreatedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PageViewCountResponse {
  pageViewCount: number;
}

export interface RealtimeUserCountResponse {
  realtimeUserCount: number;
}

export enum PromptType {
  // 한줄 자기소개
  TYPE_PRE_MEETUP_INTRODUCTION_PROMPT = 5,
  // 모임장 전달 신청 이유
  TYPE_PRE_MEETUP_EXPECTATION_PROMPT = 6,
  // 관계/친목, 주제 학습/워크숍, 대화
  TYPE_PRE_MEETUP_PURPOSE_PROMPT = 7,
  // 이 모임에서 나는 어떠한 역할을 할 수 있을까요?
  TYPE_PRE_MEETUP_ROLE_PROMPT = 8,
  // 모임장 개별 사전 질문
  TYPE_PRE_MEETUP_HOST_PROMPT = 1,
  // 회차별 질문
  TYPE_CURRICULUM_PROMPT = 2,
}

export enum AnswerType {
  ANSWER_TYPE_TEXT = 1,
  ANSWER_TYPE_IMAGE = 2,
  ANSWER_TYPE_IMAGE_AND_TEXT = 3,
  ANSWER_TYPE_MULTIPLE_CHOICE = 4,
}

export enum AnswerAccessScope {
  ACCESS_SCOPE_PUBLIC = 2,
  ACCESS_SCOPE_ATTENDEE = 1,
  ACCESS_SCOPE_PRIVATE = 0,
}

export enum BookmarkButtonType {
  CARD_SECTION = 1,
  BOOKMAKR_PAGE = 2,
  CTA_BUTTON = 3,
  PREOPEN_SECTION = 4,
}

export interface Attendance {
  id: number;
  createdAt: string;
  canceledAt: string | null;
  type: AttendanceType;
  state: AttendanceState;
  meetup: Meetup;
  sessionId: number | null;
  user: User;
  payment: Payment;
  reasonForChange?: string;
}

export interface Payment {
  id: number;
  orderId: number;
  totalAmount: number;
  totalCanceledAmount: number | undefined;
  creditCardPayment: CreditCardPayment | null;
  pointPayment: PointPayment | null;
  createdAt: string;
  canceledAt: string | null;
}

export interface CreditCardPayment {
  creditCard: CreditCard;
  amount: number;
  canceledAmount: number | null;
  monthlyInstallment: number;
}

export interface PointPayment {
  consumedPoints: Point[] | null;
  canceledPoints: Point[] | null;
  amount: number;
  canceledAmount: number | null;
}

// 모임 회차+일정 정보 (예. 소모임은 1회차 모임)
export interface Session {
  id: number;
  place: Place | null;
  order: number;
  date: string | null;
  duration: number | null; // 진행 시간 in 분 (MIN)
  online: boolean;
  curriculum: string;
  title: string;

  cost: number | null; // 놀러가기 비용 여부
}

export interface Place {
  id: number | null; // 장소 정보에 이름만 있는 경우, id를 임의로 채우는 게 더 나을까?
  thumbnailUrl: string | null;
  extraImageUrls: string[] | null;
  name: string;
  address: string | null;
  introduction: string | null;
}

export interface Installment {
  cardName: string;
  info: string;
}

export interface User {
  id: string;
  profileImageUrl: string | null;
  name: string | null;
  birthday: string | null;
  nickname: string;
  introduction: string | null;
  title: string | null;
  favoriteMovie: string | null;
  followWishCount: number;
  email: string | null;
  phoneNumber: string | null;
  gender: Gender | null;
  instagramAccount?: string;
  privacyPolicyConsent: boolean;
  servicePolicyConsent: boolean;
  marketingConsent?: boolean;
  marketingPreference?: MarketingPreference;
  qualified: boolean | null;
  pointBalance: number;
  createdAt: string | null;
}

export interface CreditCard {
  id: string;
  bankName: string;
  first4Digit: string;
  last4Digit: string;
  state: number;
  createdAt: string;
}

export interface Point {
  id: string;
  title: string;
  amount: number;
  balance: number;
  expirationDate: string;
  createdAt: string;
  boundary: {
    message: string;
    meetup: {
      meetupId: string | null;
      meetupType: number | null;
    };
  } | null;
}

export interface CreditCardsResponse {
  creditCards: CreditCard[];
  pagination: PaginationResponse;
}

export interface CreditCardResponse {
  creditCard: CreditCard;
}

export interface AuthToken {
  accessToken: string;
  refreshToken: string;
}

export interface AuthResponse {
  tokens: AuthToken;
  user: User;
}

export interface AuthOTPResponse {
  phoneNumber: string;
  passwordExpirationDate: string | null;
  verified: boolean;
}

export interface MarketingPreference {
  bookmarkToOpenNotification: boolean;
  bookmarkToReopeningRequest: boolean;
  bookmarkToWaitlist: boolean;
  weeklyPromotionCrm: boolean;
}

export interface Membership {
  id: number;
  type: number;
  expirationDate: string | null;
  canceledAt: string | null;
  payment: Payment | null;
}

export interface MembershipBenefit {
  visitTicketCount: number;
  usedVisitTicketCount: number;
  discountPriceAvailable: boolean;
  gatheringAvailable: boolean;
}

export interface MembershipResponse {
  memberships: Membership[];
  visitTickets: VisitTicket[];
  benefit: MembershipBenefit;
}

export interface VisitTicket {
  id: string;
  used: boolean;
  expirationDate: string;
}

export interface PointResponse {
  points: Point[];
}

export interface PointBalanceResponse {
  pointBalance: number;
}

// 영화, 시리즈(TV 드라마, 예능 등), 유튜브, 책 모든 컨텐츠 포함
export interface Content {
  id: number;
  sessionId: number | null;
  thumbnailUrl: string | null;
  extraImageUrls: string[];
  title: string;
  description: string | null;
  contentUrl: string | null;
  contentType: ContentType;
  creator: string | null;
  creatorTitle: CreatorTitle | null;
  releaseDate: string | null; // 발표 날짜(YYYY-MM-DD), movie & series는 연도만 유효한 데이터
}

export interface EmojiReaction {
  id: number;
  emojiCode: string;
  user: User;
  answerId: number | null;
  reviewId: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface EmojiReactionResponse {
  emojiReactions: EmojiReaction[];
}

export interface Raffle {
  id: number;
  meetupId: number | null;
  title: string;
  ticketCount: number;
  ticketCapacity: number;
  openingDate: string;
  closingDate: string;
  announcementDate: string;
  createdAt: string;
}

export interface RaffleUserInteraction {
  qualifiedForRaffle: boolean;
  raffleTicket: number;
}

export interface RaffleResponse {
  raffle: Raffle;
  meetup: Meetup | null;
  userInteraction: RaffleUserInteraction;
}

export interface RafflesResponse {
  raffles: Raffle[];
  pagination: PaginationResponse;
}

export interface RaffleTicket {
  id: number;
  raffleId: number;
  userId: string;
  status: RaffleTicketStatus;
  createdAt: string;
}

export interface RaffleTicketResponse {
  raffleTicket: RaffleTicket;
}

export enum EmojiCode {
  EMOJI_CODE_CLAP = "👏",
  EMOJI_CODE_THUMBS_UP = "👍",
}

export enum EmojiState {
  STATE_ACTIVE = 1,
  STATE_INACTIVE = -1,
}

export enum MembershipType {
  SALON_PREMIUM = 1,
  CREATOR = 2, // 정기 모임 모임장 혜택
}

export enum AttendanceState {
  CONFIRMED = 1,
  STANDBY = 0,
  CANCELED = -1,
}

export enum AttendanceType {
  REGISTER = 1, // 일반 신청
  VISIT = 2, // 놀러가기
}

export enum WishType {
  TYPE_WAITLIST = 1,
  TYPE_REOPENING = 2,
  TYPE_OPEN_NOTIFICATION = 3,
  TYPE_BOOKMARK = 4,
  TYPE_FOLLOW = 5,
  TYPE_VISIT_WAITLIST = 6,
  TYPE_NEW_PROMPT_NOTIFICATION = 7,
}

export enum WishState {
  STATE_ACTIVE = 1,
  STATE_INACTIVE = -1,
}

export enum Gender {
  FEMALE = "female",
  MALE = "male",
}

export enum GenderRatioOption {
  NO_PREFERENCE = "상관 없음",
  ONE_TO_ONE = "1:1 성비",
  SAME_GENDER = "동일 성별",
}

export enum ContentType {
  MOVIE = "movie",
  SERIES = "series",
  YOUTUBE = "youtube",
  BOOK = "book",
  ARTICLE = "article",
}

enum CreatorTitle {
  PRODUCER = "producer",
  YOUTUBER = "youtuber",
  AUTHOR = "author",
}

export enum MeetupType {
  SALON = 1,
  EVENT = 2,
  GATHERING = 3,
  RENTAL = 4,
}

export interface TentChannel {
  id: string;
  category: string;
  title: string;
  subtitle: string;
  thumbnailUrl: string;
  exampleImageUrls: string[];
  description: string | null;
  curriculum: string | null;
  communityGuide: string | null;
  meetupDescription: string | null;
  nicknameTitle: string | null;
  memberCount: number;
  maxCapacity: number;
  official: boolean;
  closed: boolean;
  host: {
    id: string;
    profileImageUrl: string;
    nickname: string;
    title: string | null;
    introduction: string | null;
  } | null;
  additionalInformation: any;
  lastMembershipCreatedAt: string | null;
  lastMessageSentAt: string | null;
  createdAt: string;
  state: number;
  wishCounts: {
    type: TentChannelWishType;
    count: number;
  }[];
}

export interface TentChannelsResponse {
  channels: TentChannel[];
  pagination: PaginationResponse;
}

export enum TentChannelWishType {
  TYPE_PRE_OPEN = 0,
}

export enum TentChannelState {
  PRE_PUBLISHED = 2,
  PUBLISHED = 1,
  PENDING = 0,
  DELETED = -1,
}

export enum TentChannelOrderType {
  LAST_MEMBERSHIP_CREATED_DESC = "last_membership_created_desc",
  CREATED_DESC = "created_desc",
  CREATED_ASC = "created_asc",
  LAST_MESSAGE_SENT_DESC = "last_message_sent_desc",
  RECOMMENDED = "recommended",
  PRE_OPEN_WISH_COUNT_DESC = "pre_open_wish_count_desc",
}

export enum MeetupOrderType {
  SESSION_ASC = "session_asc",
  SESSION_DESC = "session_desc",
  CREATED_DESC = "created_desc",
  CREATED_ASC = "created_asc",
  RELEASED_DESC = "released_desc",
  RELEASED_ASC = "released_asc",
  SPARE_SEAT_ASC = "spare_seat_asc", // 임시, 자리가 몇 안남은 순, 마감 임박
  SALON_CATEGORY_ASC = "salon_category_asc",
  OPEN_NOTIFICATION_WISH_COUNT_DESC = "open_notification_wish_count_desc",
  WAITLIST_WISH_COUNT_DESC = "waitlist_wish_count_desc",
  OPENING_DATE_DESC = "opening_date_desc",
  HOST_SALON_REVIEW_COUNT_DESC = "host_salon_review_count_desc",
  HOST_EVENT_REVIEW_COUNT_DESC = "host_event_review_count_desc",
  HOST_GATHERING_REVIEW_COUNT_DESC = "host_gathering_review_count_desc",
}

export enum TagOrderType {
  ASC = "asc",
  DESC = "desc",
  ALPHABET_ASC = "alphabet_asc",
  ALPHABET_DESC = "alphabet_desc",
}

export enum AttendanceOrderType {
  ASC = "asc",
  DESC = "desc",
}

export enum AnswerOrderType {
  LATEST = "latest",
  OLDEST = "oldest",
  REACTION_COUNT_DESC = "reaction_count_desc",
}

export enum ReviewOrderType {
  CREATED_AT_DESC = "created_at_desc",
  CREATED_AT_ASC = "created_at_asc",
  RATING_DESC = "rating_desc",
  RATING_ASC = "rating_asc",
  REACTION_COUNT_DESC = "reaction_count_desc",
  REVIEW_TEXT_LENGTH_DESC = "review_text_length_desc",
}

export enum NetworkOrderType {
  FREQUENT_CONTACT = "frequent_contact",
  RECENT_CONTACT = "recent_contact",
}

export enum MemberType {
  USER = "user",
  HOST = "host",
  ADMIN = "admin",
  MEMBER = "member",
}

export enum RoleStatusType {
  SELECTEE = "selectee",
  APPLICANT = "applicant",
  PROSPECT = "prospect",
}

export enum RoleType {
  ROLE_ASSISTANT = "assistant", // 모임지기
  ROLE_GATHERING_LEADER = "gathering_leader", // 번개돌이
  ROLE_PHOTOGRAPHER = "photographer", // 포토그래퍼
  ROLE_GOOD_TO_MUCH_TALKER = "good_too_much_talker", // 굿투머치토커
  ROLE_BGM_FAIRY = "bgm_fairy", // BGM 요정
  ROLE_ALARM_FAIRY = "alarm_fairy", // 알람 요정
  ROLE_ADVOCATE = "advocate", // 선의의 옹호자
}

export enum PaycheckStatusType {
  PENDING = 0,
  PAID = 1,
  REJECTED = 2,
  DELETED = -1,
}

export enum PaycheckType {
  HOST_PAYMENT = "host_payment",
  PER_SESSION_COST = "per_session_cost",
  SALON_GATHERING_GRANT = "salon_gathering_grant",
  OTHER = "other",
}

export enum PaymentDocumentType {
  IDENTITY_CARD = "identity_card",
  RECEIPT = "receipt",
  BANK_BOOK = "bank_book",
  BUSINESS_LICENSE = "business_license",
  TAX_INVOICE = "tax_invoice",
}

export enum NotificationTemplateTitleType {
  MEMBER_INVITATION = "member_invitation",
  MEMBER_INVITATION_SELF = "member_invitation_self",
}

export enum RaffleTicketStatus {
  PENDING = 'pending',
  WON = 'won',
  LOST = 'lost',
  EXPIRED = 'expired',
}

export interface AdEvenItem {
  link: string;
  thumbnailUrl: string;
  title: string;
  summary: string;
  briefDate: string;
  briefLocation: string;
}

export interface TempPromotion {
  apiKey: string;
  headerImg: {
    mobile?: string;
    pc: string;
  };
  items: {
    description: string;
    interviewText: string;
    interviewTitle: string;
    mainImg: string;
    outLink: string;
    relatedMeetups: {
      img: string;
      link: string;
      text: string;
    }[];
    subTitle: string;
    title: string;
    titleKr: string;
  }[];
  mainColor: string;
  name: string;
  pointButton?: string;
  pointLink?: string;
  pointText?: string;
  promotionEndDate?: string;
  subjectTitle: string;
}

// 2023 연말결산 해커톤용 interface
export interface UserVisualJournal {
  id: string;
  uuid: string;
  user: User;
  createdAt: string;
}

export interface VisualJournalPrompt {
  id: string;
  question: string;
  description: string | null;
  answerCount: number;
  createdAt: string;
  recentlyAnsweredAt: string | null;
}

export interface VisualJournalAnswer {
  id: string;
  visualJournalPromptId: string;
  user: User;
  text: string | null;
  generatedImageUrls: string[] | null;
  likeCount: number;
  createdAt: string;
}

export interface VisualJournalLike {
  id: string;
  visualJournalAnswerId: string;
}

export interface UserVisualJournalResponse {
  userVisualJournal: UserVisualJournal;
  answeredPrompts: {
    prompt: VisualJournalPrompt;
    answer: VisualJournalAnswer;
  }[];
}

export interface VisualJournalPromptsResponse {
  visualJournalPrompts: VisualJournalPrompt[];
  pagination: PaginationResponse;
}

export interface VisualJournalPromptResponse {
  visualJournalPrompt: VisualJournalPrompt;
}

export interface VisualJournalAnswersResponse {
  answers: {
    answer: VisualJournalAnswer;
    like: VisualJournalLike | null;
  }[];
  pagination: PaginationResponse;
}

export interface VisualJournalAnswerResponse {
  answer: VisualJournalAnswer;
}

export interface VisualJournalLikeResponse {
  visualJournalLike: VisualJournalLike | null;
}

export enum UserVisualJournalIdType {
  USER_ID = "userId",
  UUID = "uuid",
}

export enum VisualJournalPromptOrderType {
  ASC = "asc",
  DESC = "desc",
  RECENTLY_ANSWERED_AT_DESC = "recently_answered_at_desc",
  RECENTLY_ANSWERED_AT_ASC = "recently_answered_at_asc",
}

export enum VisualJournalAnswerOrderType {
  ASC = "asc",
  DESC = "desc",
  LIKE_COUNT_DESC = "like_count_desc",
  LIKE_COUNT_ASC = "like_count_asc",
}
